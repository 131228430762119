import logo from "./logo.png"
const clientData = {
    client_entity: 24,
    attorney_firm: 'Orcutt Law',
    attorney_name: 'John Orcutt',
    attorney_number: '+1-919-646-2654',
    attorney_email: 'jto9750@gmail.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_orcutt_law+(720p).mp4',
    mainColor: '#464646',
    secondaryColor: '#D21919',
    siteLink: 'http://www.billsbills.com',
    logo
}

export default clientData